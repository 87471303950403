import React, { useState } from "react";
import style from "./checkout.module.scss";
import CommonModal from "../../UI/CommonModal/CommonModal";
import { ReactComponent as AddPlusIcon } from "../../assets/Add_plus_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete_icon.svg";
import { ReactComponent as PencilIcon } from "../../assets/Pencil_icon.svg";
import { Input } from "antd";
import API from "../../Api/api";
import { antdMessageError } from "./utills";

export const SavedAddress = ({
  setIsModalOpen,
  isModalOpen,
  userDetails,
  setIndexOfShipingAddress,
  indexOfShipingAddress,
  setUserDetails,
  setEditingAddress,
  setEditingIndex,
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  userDetails: any;
  setIndexOfShipingAddress: any;
  indexOfShipingAddress: number;
  setUserDetails: any;
  setEditingAddress: any;
  setEditingIndex: any;
}) => {
  const [tempAddresses, setTempAddresses] = useState(
    userDetails?.shippingAddress || []
  );
  const [localIndex, setLocalIndex] = useState<number | null>(null);
  const handleDelete = (index: number) => {
    const updatedAddresses = [
      ...tempAddresses.slice(0, index),
      ...tempAddresses.slice(index + 1),
    ];
    setTempAddresses(updatedAddresses);
    if (indexOfShipingAddress >= updatedAddresses.length) {
      setIndexOfShipingAddress(Math.max(0, updatedAddresses.length - 1));
    }
  };

  const handleSave = async () => {
    try {
      if( userDetails.shippingAddress.length !== 0){
        if (localIndex === null || localIndex === undefined) {
          antdMessageError("Please select address before save");
          return;
        }
      }
      setIndexOfShipingAddress(localIndex);
      const payload = {
        shippingAddress: userDetails?.shippingAddress,
        billingAddress: userDetails?.billingAddress,
      };

      const response = await API.payment.deleteUserData({
        userdata: payload,
        accessToken: localStorage.getItem("accessToken") || "",
      });

      if (API.validators.checkSuccessCode(response)) {
        setIsModalOpen({ ...isModalOpen, savedAddressModal: false });
      }
    } catch (error: any) {
      console.error("Error updating addresses:", error);
    }
  };
  const handlePopup = () => {
    setIsModalOpen({ ...isModalOpen, savedAddressModal: false });
    setTimeout(() => {
      setIsModalOpen((prev: any) => ({
        ...prev,
        addressModal: true,
      }));
    }, 200);
  };
  return (
    <CommonModal
      isModalVisible={() =>
        setIsModalOpen({ ...isModalOpen, savedAddressModal: false })
      }
      visible={isModalOpen?.savedAddressModal}
      centered
    >
      {userDetails?._id && (
        <div style={{ padding: "20px" }}>
          <div className={style["add-card-heading"]}>Saved Address</div>
          <div style={{ marginTop: "16px" }}>
            <div
              className={style["add-saved-address"]}
              onClick={() => {
                setEditingAddress(null);
                setEditingIndex(null);
                handlePopup();
              }}
            >
              <AddPlusIcon />
              <div>Add Address</div>
            </div>
            <div className={style["address-list"]}>
              {userDetails?.shippingAddress?.length > 0 ? (
                userDetails?.shippingAddress.map(
                  (address: any, index: number) => (
                    <div
                      onClick={() => {
                        setLocalIndex(index);
                      }}
                      className={style["select-address-container"]}
                      style={{
                        border: index === localIndex ? "1px solid black" : "",
                      }}
                    >
                      <div className={style["flex-justify-space-between"]}>
                        <div
                          className={style["flex-center"]}
                          style={{ gap: "10px" }}
                        >
                          <div className={style["address-name"]}>
                            {address?.name}
                          </div>
                          <PencilIcon
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingAddress(address);
                              setEditingIndex(address?._id);
                              handlePopup();
                            }}
                          />
                        </div>
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setUserDetails({
                              ...userDetails,
                              shippingAddress: [
                                ...userDetails?.shippingAddress.slice(0, index),
                                ...userDetails?.shippingAddress.slice(
                                  index + 1
                                ),
                              ],
                            });
                            handleDelete(index);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className={style["address"]}
                        style={{ marginTop: "10px" }}
                      >
                        {address?.area}
                        {address?.state}
                        {address?.country}
                        {address?.pincode}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div>No shipping addresses available.</div>
              )}
            </div>
          </div>

          <div className={style["button-container"]}>
            <button
              onClick={() => {
                setIsModalOpen({ ...isModalOpen, savedAddressModal: false });
              }}
              className={style["discard-btn"]}
            >
              Discard
            </button>
            <button onClick={handleSave} className={style["save-btn"]}>
              Save
            </button>
          </div>
        </div>
      )}
    </CommonModal>
  );
};
