import API from "./api";

export async function CSCGetAccessToken(refreshToken) {
  if (refreshToken) {
    const resp = await fetch(
      `${process.env.REACT_APP_API_URL_V2}/authorization/refresh`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
    if (resp.status === 200) {
      const data = await resp.json();
      return data.accessToken;
    } else {
      //   logOutHandlerForMobile(JSON.stringify({ message: "LOGOUT_SUCCESS" }));
      //   window.parent.postMessage({ source: "logout-from-user" }, "*");
      throw new Error("Error refreshing access token");
    }
  } else {
    throw new Error("Error user not logged in");
  }
}
export function CSCCheckJWTExpired(token) {
  if (!token) {
    return true;
  }
  const jwt = token.split(".");
  if (jwt.length !== 3) {
    return true;
  }
  const payload = JSON.parse(atob(jwt[1]));
  const now = new Date();
  return now.getTime() >= payload.exp * 1000;
}

export function getDomainFromUrl(url) {
  try {
    const urlObj = new URL(url); // Create a URL object
    return `${urlObj.protocol}//${urlObj.hostname}`; // Return the protocol and hostname
  } catch (error) {
    console.error("Invalid URL:", error);
    return null; // Return null if the input is not a valid URL
  }
}

export async function getLoginDetails(authzCode, client, rid) {
  const resp = await fetch(
    `${process.env.REACT_APP_API_URL_V2}/authorization/login?clientId=${client}&rid=${rid}`,
    {
      headers: {
        authorization: `Bearer ${authzCode}`,
      },
      method: "GET",
    }
  );
  const data = await resp.json();
  return data;
}
