import React, { useEffect, useState } from "react";
import style from "./checkout.module.scss";
import CommonModal from "../../UI/CommonModal/CommonModal";
import { Input } from "antd";
import API from "../../Api/api";
import { antdMessageError, antdMessageSuccess } from "./utills";
export const AddAddress = ({
  setIsModalOpen,
  isModalOpen,
  userDetails,
  setUserDetails,
  editingAddress,
  setEditingAddress,
  editingIndex,
  setTriggerApi,
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  userDetails: any;
  setUserDetails: any;
  editingAddress: any;
  setEditingAddress: any;
  editingIndex: string | null;
  setTriggerApi: any;
}) => {
  const [address, setAddress] = useState({
    name: "",
    state: "",
    country: "",
    pincode: "",
    area: "",
  });
  const [originalAddress, setOriginalAddress] = useState(
    editingAddress || null
  );

  useEffect(() => {
    if (editingAddress) {
      setAddress(editingAddress);
      setOriginalAddress(editingAddress);
    }
  }, [editingAddress]);

  const saveData = async () => {
    if (
      !address.state ||
      !address.pincode ||
      !address.country ||
      !address.area
    ) {
      antdMessageError("Please fill all required fields");
      return;
    }

    if (JSON.stringify(address) === JSON.stringify(originalAddress)) {
      antdMessageError("No changes detected.");
      return;
    }

    try {
      let updatedUser = { ...userDetails };
      console.log(updatedUser, "updatedUser");
      const payload = {
        address: address,
      };
      if (editingIndex !== null) {
        await API.payment.EditUserData({
          userdata: payload,
          accessToken: localStorage.getItem("accessToken") || "",
          id: editingIndex,
        });
        updatedUser.shippingAddress = updatedUser.shippingAddress.map(
          (addr: any) => (addr._id === editingIndex ? address : addr)
        );

        setUserDetails(updatedUser);
      } else {
        updatedUser.shippingAddress = [address];

        const data = await API.payment.PutUserData({
          userdata: updatedUser,
          accessToken: localStorage.getItem("accessToken") || "",
        });

        if (API.validators.checkSuccessCode(data)) {
          antdMessageSuccess("Address Added Successfully");
          setUserDetails((prev: any) => ({
            ...prev,
            shippingAddress: [...(prev.shippingAddress || []), address],
          }));
        }
      }
      discardData();
      setTriggerApi((prev: boolean) => !prev);
    } catch (error: any) {
      antdMessageError(
        error?.response?.data?.message || "Failed to update address."
      );
    }
  };

  const discardData = () => {
    setAddress({ name: "", state: "", country: "", pincode: "", area: "" });
    setEditingAddress(null);
    setIsModalOpen({ ...isModalOpen, addressModal: false });
  };
  return (
    <CommonModal
      isModalVisible={() => {
        setIsModalOpen({ ...isModalOpen, addressModal: false });
        setAddress({ name: "", state: "", country: "", pincode: "", area: "" });
      }}
      visible={isModalOpen?.addressModal}
      centered
    >
      <div style={{ padding: "20px" }}>
        <div className={style["add-card-heading"]}>
          {editingIndex !== null ? "Edit Address" : "Add New Address"}
        </div>
        <div style={{ marginTop: "16px" }}>
          <div className={style["input-text-container1"]}>
            <div className={style["pincode-input"]}>
              <Input
                value={address.pincode || ""}
                onChange={(e) => {
                  const reg = /^$|^\d{0,9}$/;
                  const value = e.target.value;
                  if (reg.test(value)) {
                    setAddress({ ...address, pincode: e.target.value });
                  }
                }}
                placeholder="PIN / ZIP Code"
              />
            </div>
            <div className={style["pincode-input"]}>
              <Input
                value={address.state || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = /^[a-zA-Z\s]{0,100}$/.test(value); 
                  if (isValid) {
                    setAddress({ ...address, state: value });
                  }
                }}
                placeholder="State"
              />
            </div>
            <div className={style["pincode-input"]}>
              <Input
                value={address.country || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = /^[a-zA-Z\s]{0,100}$/.test(value); 
                  if (isValid) {
                    setAddress({ ...address, country: value });
                  }
                }}
                placeholder="Country"
              />
            </div>
          </div>
          <div className={style["address-name-input"]}>
            <Input
              value={address.name || ""}
              onChange={(e) => {
                setAddress({ ...address, name: e.target.value });
              }}
              placeholder="First Name & Last Name"
            />
          </div>
          <div className={style["address-name-input"]}>
            <Input
              value={address.area || ""}
              onChange={(e) => {
                setAddress({ ...address, area: e.target.value });
              }}
              placeholder="Full Address (House number, area... etc)"
            />
          </div>
        </div>

        <div className={style["button-container"]}>
          <button
            onClick={() => {
              discardData();
            }}
            className={style["discard-btn"]}
          >
            Discard
          </button>
          <button
            onClick={() => {
              saveData();
            }}
            className={style["save-btn"]}
          >
            Save
          </button>
        </div>
      </div>
    </CommonModal>
  );
};
