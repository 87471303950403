import axios, { AxiosResponse } from "axios";
import {
  CSCCheckJWTExpired,
  CSCGetAccessToken,
  getLoginDetails,
} from "./utills";
import { removeURLParam } from "../component/Checkout/utills";

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;

enum REST_METHODS {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
  PUT = "put",
}
const apiCaller = async (
  {
    method,
    params,
    url,
    data,
    headers,
  }: {
    method: REST_METHODS;
    params?: Record<string, unknown>;
    url: string;
    data?: Record<string, unknown> | FormData;
    headers?: any;
  } = {
    method: REST_METHODS.GET,
    params: {},
    data: {},
    headers: {},
    url: "",
  }
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const authzCode = searchParams.get("authorizationCode") || "";
  const client = searchParams.get("clientId") || "";
  const rid = searchParams.get("rid") || "";
  if (authzCode && authzCode.length > 8) {
    try {
      const loginDetails = await getLoginDetails(authzCode, client, rid);
      localStorage.setItem("accessToken", loginDetails?.accessToken || "");
      localStorage.setItem("refreshToken", loginDetails?.refreshToken || "");
      headers = { ...headers, Authorization: `Bearer ${loginDetails?.accessToken }` };
      removeURLParam("authorizationCode");
    } catch (error) {
      const refreshToken = localStorage.getItem("refreshToken");
      if(refreshToken && refreshToken.length > 18){
        const accessToken = await CSCGetAccessToken(refreshToken);
        localStorage.setItem("accessToken", accessToken || "");
        headers = { ...headers, Authorization: `Bearer ${accessToken}` };
      }
    }
  } else {
    let accessToken = localStorage.getItem("accessToken");
    let refreshToken = localStorage.getItem("refreshToken");
    if (accessToken != undefined && accessToken.length > 18) {
      if (!CSCCheckJWTExpired(accessToken)) {
        headers = { ...headers, Authorization: `Bearer ${accessToken}` };
      } else {
        accessToken = await CSCGetAccessToken(refreshToken);
        localStorage.setItem("accessToken", accessToken || "");
        headers = { ...headers, Authorization: `Bearer ${accessToken}` };
      }
    } else if (refreshToken && refreshToken.length > 18 && !accessToken) {
      accessToken = await CSCGetAccessToken(refreshToken);
      localStorage.setItem("accessToken", accessToken || "");
      headers = { ...headers, Authorization: `Bearer ${accessToken}` };
    }
  }

  if (method === REST_METHODS.GET) {
    return axios.get(url, { params, headers });
  }
  return axios({
    method: method,
    url,
    data,
    headers,
    params,
  });
};
const API = {
  validators: {
    checkSuccessCode: (response: AxiosResponse): boolean => {
      return Math.floor(response.status / 100) === 2;
    },
    matchStatusCode: (response: AxiosResponse, statusCode: number): boolean => {
      return response.status === statusCode ? true : false;
    },
  },
  user: {
    renewalStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/payment/gateway/renewal/${id}/status`,
      });
    },
    transitionStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/payment/gateway/transaction/${id}/status`,
      });
    },
    RedirectUserToContent: ({
      clientId,
      clientContentId,
      pageType,
    }: {
      clientId?: string;
      clientContentId?: string;
      pageType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/redirect-to-content`,
        params: {
          ...(clientId && { clientId }),
          ...(clientContentId && { clientContentId }),
          ...(pageType && { pageType }),
        },
      });
    },
    getSubsDetails: ({
      subsId,
      tierId,
      clientId,
      accessToken,
      numberOfLicense,
      couponCode,
    }: {
      subsId: string;
      tierId: string;
      clientId: string;
      accessToken?: string;
      numberOfLicense?: number;
      couponCode: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/price?subscriptionId=${subsId}&tierId=${tierId}&clientId=${clientId}&numberOfLicense=${numberOfLicense}&couponCode=${couponCode}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    getSubsDetailsAnon: ({
      subsId,
      tierId,
      clientId,
      numberOfLicense,
      couponCode,
    }: {
      subsId: string;
      tierId: string;
      clientId: string;
      numberOfLicense: number;
      couponCode: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/anonprice?subscriptionId=${subsId}&tierId=${tierId}&clientId=${clientId}&numberOfLicense=${numberOfLicense}&couponCode=${couponCode}`,
      });
    },
    getCheckoutSettings: ({ clientId }: { clientId?: any }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/client/get-checkout-settings/${clientId}`,
      });
    },
    getLoginSettings: ({ clientId }: { clientId: any }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/client/login-customization?clientId=${clientId}`,
      });
    },
    CyberSourceSetUp: ({
      clientId,
      cardExpiryMonth,
      cardExpiryYear,
      cardNumber,
      securityCode,
      cardTokenId,
      cardType,
      nameOnCard,
    }: {
      clientId: any;
      cardExpiryMonth: any;
      cardExpiryYear: any;
      cardNumber: any;
      securityCode?: string;
      cardTokenId?: string;
      cardType?: string;
      nameOnCard?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/cybersource/auth-setup`,
        data: {
          clientId,
          ...(cardExpiryMonth && { cardExpiryMonth }),
          ...(cardExpiryYear && { cardExpiryYear }),
          ...(cardNumber && { cardNumber }),
          ...(securityCode && { securityCode }),
          ...(cardTokenId && { cardTokenId }),
          ...(cardType && { cardType }),
          ...(nameOnCard && { nameOnCard }),
        },
      });
    },
    contentPassPurchase: ({
      clientId,
      clientContentId,
      type,
      userToken,
      paywallType,
      paywallId,
    }: {
      clientId: any;
      clientContentId: any;
      type: any;
      userToken: any;
      paywallType?: string;
      paywallId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/order`,
        data: {
          clientId,
          clientContentId,
          type: type.toUpperCase(),
          paywallType,
          paywallId,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
    stripeOrderCreate: ({
      clientId,
      clientContentId,
      type,
      userToken,
      redirectUrl,
    }: {
      clientId: any;
      clientContentId: any;
      type: any;
      userToken: any;
      redirectUrl: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/order`,
        data: {
          clientId,
          clientContentId,
          type: type.toUpperCase(),
          redirectUrl: redirectUrl,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
    postRenewal: ({
      clientId,
      subscriptionId,
      tierId,
      type,
      contentDetails,
      manuallyRenewed,
      couponCode,
      consumeFreeTrial,
      anonId,
      userSourceData,
      userToken,
      paywallId,
      paywallType,
      userDetails,
      userId,
      clientReferenceId,
      amount,
      currency,
      clientContentId,
      numberOfLicense,
      landingPageId,
    }: {
      clientId: string;
      subscriptionId: string;
      tierId: string;
      type: string;
      contentDetails?: any;
      manuallyRenewed?: boolean;
      couponCode?: string;
      consumeFreeTrial?: boolean;
      anonId?: string;
      userSourceData?: string;
      userToken?: string;
      paywallId?: string;
      paywallType?: string;
      userDetails?: any;
      userId?: string;
      clientReferenceId?: string;
      amount?: number;
      currency?: string;
      clientContentId?: string;
      numberOfLicense?: any;
      landingPageId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/renewal`,
        data: {
          clientId,
          subscriptionId,
          tierId,
          type,
          contentDetails,
          manuallyRenewed,
          couponCode,
          consumeFreeTrial,
          anonId,
          // paywallType,
          // paywallId,
          userDetails,
          userId,
          clientContentId,
          ...(clientReferenceId && { clientReferenceId }),
          ...(landingPageId && { landingPageId }),

          ...(userSourceData && { userSourceData }),
          ...(amount && { amount }),
          ...(currency && { currency }),
          ...(paywallType && { paywallType }),
          ...(paywallId && { paywallId }),
          numberOfLicense: Number(numberOfLicense),
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
  },
  client: {
    GetSubscriptionSettings: ({
      clientId,
      accessToken,
    }: {
      clientId: string;
      accessToken?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}subscription/settings`,
        params: {
          ...(clientId && { clientId }),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    RedirectUserToContent: ({
      clientId,
      clientContentId,
      pageType,
    }: {
      clientId?: string;
      clientContentId?: string;
      pageType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/redirect-to-content`,
        params: {
          ...(clientId && { clientId }),
          ...(clientContentId && { clientContentId }),
          ...(pageType && { pageType }),
        },
      });
    },
    GetLoginCustomization: ({ clientId }: { clientId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/client/login-customization?clientId=${clientId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  },
  subscription: {
    SubscriptionAccessCheck: ({
      clientId,
      sessionId,
      tierId,
    }: {
      clientId: string;
      sessionId?: string | undefined;
      tierId?: string | undefined;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/access`,
        params: {
          ...(clientId && { clientId }),
          ...(sessionId && { sessionId }),
          ...(tierId && { tierId }),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  },
  payment: {
    authorizationCode: ({
      clientId,
      authzCode,
      rid,
    }: {
      clientId: string;
      authzCode: string;
      rid?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/login?clientId=${clientId}&rid=${rid}`,
        headers: {
          Authorization: authzCode,
        },
      });
    },
    refreshTokenForSubscription: ({ authzCode }: { authzCode: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/refresh`,
        headers: {
          Authorization: authzCode,
        },
      });
    },
    cyberSourceOrder: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      amount,
      currency,
      userId,
      userDetails,
      cardExpiryMonth,
      cardExpiryYear,
      cardNumber,
      nameOnCard,
      cyberSourceReferenceId,
      tokenId,
      numberOfLicense,
      clientReferenceId,
      giftingDetails,
      cardTypeNumber,
      cardType,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      amount?: number;
      currency?: String;
      userId?: string;
      userDetails?: any;
      cardExpiryMonth?: string;
      cardExpiryYear?: string;
      cardNumber?: string;
      nameOnCard?: string;
      cyberSourceReferenceId?: string;
      tokenId?: string;
      numberOfLicense?: Number;
      clientReferenceId?: string;
      giftingDetails?: any;
      cardTypeNumber?: any;
      cardType?: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/cybersource/order`,
        headers: {
          "fingerprint-session-id": `${cyberSourceReferenceId}`,
        },
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          // paywallId,
          firstTimeLogin,
          landingPageId,
          utmParameters,
          popupId,
          userDetails,
          contentType,
          userDateTime,
          contentDetails,
          // paywallType,
          userSourceData,
          successFrontendUrl,
          failureFrontendUrl,
          accessToken,
          cyberSourceReferenceId,
          cardType,
          numberOfLicense: Number(numberOfLicense),
          ...(tokenId && { tokenId }),
          ...(clientReferenceId && { clientReferenceId }),
          ...(nameOnCard && { nameOnCard }),
          ...(giftingDetails && { giftingDetails }),
          ...(amount && { amount }),
          ...(currency && { currency }),
          ...(paywallId && { paywallId }),
          ...(paywallType && { paywallType }),
          ...(cardNumber && { cardNumber }),
          ...(cardExpiryYear && { cardExpiryYear }),
          ...(cardExpiryMonth && { cardExpiryMonth }),
          ...(cardTypeNumber && { cardTypeNumber }),
        },
      });
    },
    cyberSourceOrderrenewal: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      amount,
      currency,
      userId,
      userDetails,
      cardExpiryMonth,
      cardExpiryYear,
      cardNumber,
      nameOnCard,
      cyberSourceReferenceId,
      tokenId,
      numberOfLicense,
      clientReferenceId,
      giftingDetails,
      cardTypeNumber,
      cardType,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      amount?: number;
      currency?: String;
      userId?: string;
      userDetails?: any;
      cardExpiryMonth?: string;
      cardExpiryYear?: string;
      cardNumber?: string;
      nameOnCard?: string;
      cyberSourceReferenceId?: string;
      tokenId?: string;
      numberOfLicense?: Number;
      clientReferenceId?: string;
      giftingDetails?: any;
      cardTypeNumber?: any;
      cardType?: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/cybersource/renewal`,
        headers: {
          "fingerprint-session-id": `${cyberSourceReferenceId}`,
        },
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          // paywallId,
          firstTimeLogin,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          cardType,
          // paywallType,
          userDetails,
          userSourceData,
          successFrontendUrl,
          failureFrontendUrl,
          accessToken,
          cyberSourceReferenceId,
          numberOfLicense: Number(numberOfLicense),
          ...(tokenId && { tokenId }),
          ...(clientReferenceId && { clientReferenceId }),
          ...(nameOnCard && { nameOnCard }),
          ...(giftingDetails && { giftingDetails }),
          ...(amount && { amount }),
          ...(currency && { currency }),
          ...(paywallId && { paywallId }),
          ...(paywallType && { paywallType }),
          ...(cardNumber && { cardNumber }),
          ...(cardExpiryYear && { cardExpiryYear }),
          ...(cardExpiryMonth && { cardExpiryMonth }),
          ...(cardTypeNumber && { cardTypeNumber }),
        },
      });
    },
    pgIntegration: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      amount,
      currency,
      userId,
      userDetails,
      numberOfLicense,
      clientReferenceId,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      amount?: number;
      currency?: String;
      userId?: string;
      userDetails?: any;
      numberOfLicense?: number;
      clientReferenceId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/order`,
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          // paywallId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          // paywallType,
          successFrontendUrl,
          failureFrontendUrl,
          userId,
          userDetails,
          ...(clientReferenceId && { clientReferenceId }),
          ...(userSourceData && { userSourceData }),
          ...(amount && { amount }),
          ...(currency && { currency }),
          ...(paywallId && { paywallId }),
          ...(paywallType && { paywallType }),
          numberOfLicense: Number(numberOfLicense),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    pgIntegrationOnetimeForAll: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      amount,
      currency,
      userId,
      userDetails,
      numberOfLicense,
      clientReferenceId,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      amount?: number;
      currency?: String;
      userId?: string;
      userDetails?: any;
      numberOfLicense?: number;
      clientReferenceId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/createDiscountPurchase`,
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          paywallId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          paywallType,
          successFrontendUrl,
          failureFrontendUrl,
          amount,
          currency,
          userId,
          userDetails,
          ...(clientReferenceId && { clientReferenceId }),
          ...(userSourceData && { userSourceData }),
          numberOfLicense: Number(numberOfLicense),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    renewalSubscriptionOrderForStripe: ({
      clientId,
      subscriptionId,
      tierId,
      type,
      contentDetails,
      manuallyRenewed,
      couponCode,
      consumeFreeTrial,
      anonId,
      userSourceData,
      accessToken,
      redirectUrl,
      amount,
      currency,
      numberOfLicense,
    }: {
      clientId: string;
      subscriptionId: string;
      tierId: string;
      type: string;
      contentDetails: any;
      manuallyRenewed?: boolean;
      couponCode?: string;
      consumeFreeTrial?: boolean;
      anonId?: string;
      userSourceData?: string;
      accessToken?: string;
      redirectUrl: string;
      amount: any;
      currency: any;
      numberOfLicense?: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/renewal`,
        data: {
          clientId,
          subscriptionId,
          tierId,
          type,
          contentDetails,
          manuallyRenewed,
          couponCode,
          consumeFreeTrial,
          anonId,
          redirectUrl,
          ...(amount && { amount }),
          ...(currency && { currency }),
          ...(userSourceData && { userSourceData }),
          numberOfLicense: Number(numberOfLicense),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    subscriptionOrderForStripe: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      redirectUrl,
      amount,
      currency,
      numberOfLicense,
      userDetails,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      redirectUrl?: string;
      amount?: number;
      currency?: string;
      numberOfLicense?: any;
      userDetails?: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/order`,
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          userDetails,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          successFrontendUrl,
          failureFrontendUrl,
          redirectUrl,
          ...(paywallType && { paywallType }),
          ...(paywallId && { paywallId }),
          ...(userSourceData && { userSourceData }),
          ...(amount && { amount }),
          ...(currency && { currency }),
          numberOfLicense: Number(numberOfLicense),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    CallRouting: ({
      clientId,
    }: // accessToken,
    {
      clientId: string;
      // accessToken: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/payment/gateway-config?clientId=${clientId}`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
    },
    userDetails: ({
      clientId,
      accessToken,
    }: {
      clientId: string;
      accessToken: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/prefill-user-details?clientId=${clientId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    getUserDetails: ({
      clientId,
      accessToken,
    }: {
      clientId: string;
      accessToken: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/unified-user-details`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    EditUserData: ({
      userdata,
      accessToken,
      id,
    }: {
      userdata: any;
      accessToken?: string;
      id:string | null;
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL_V2}/authorization/update-address?shippingAddress=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: userdata,
      });
    },
    deleteUserData: ({
      userdata,
      accessToken,
    }: {
      userdata: any;
      accessToken?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL_V2}/authorization/delete-address`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: userdata,
      });
    },
    getCardDetails: ({ clientId }: { clientId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/cybersource/cardDetails?clientId=${clientId}`,
      });
    },
    addCardDetails: ({
      clientId,
      cardExpiryMonth,
      cardExpiryYear,
      cardNumber,
      nameOnCard,
      cardType,
      securityCode,
    }: {
      clientId: string;
      cardExpiryMonth: string;
      cardExpiryYear: string;
      cardNumber: string;
      nameOnCard: string;
      cardType: string;
      securityCode: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL_V2}/cybersource/add-card`,
        data: {
          clientId,
          cardExpiryMonth,
          cardExpiryYear,
          cardNumber,
          nameOnCard,
          cardType,
          securityCode,
        },
      });
    },
    PutUserData: ({
      userdata,
      accessToken,
    }: {
      userdata: any;
      accessToken?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.PUT,
        url: `${API_URL_V2}/authorization/edit-user-details`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: userdata,
      });
    },
    PaypalOrderForRenewal: ({ callPaypalPrams }: { callPaypalPrams: any }) => {
      console.log(callPaypalPrams);

      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/paypal/renewal`,
        data: callPaypalPrams,
        headers: {
          Authorization: `Bearer ${callPaypalPrams.accessToken}`,
        },
      });
    },
    CallPaypal: ({ callPaypalPrams }: { callPaypalPrams: any }) => {
      console.log(callPaypalPrams);
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/paypal/order`,
        data: callPaypalPrams,
        headers: {
          Authorization: `Bearer ${callPaypalPrams.accessToken}`,
        },
      });
    },
    abortTransaction: ({
      type,
      id,
      event,
    }: {
      type: string;
      id: string;
      event: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/abort`,
        data: {
          id,
          type,
          event,
        },
      });
    },
  },
  content: {
    convertCurrency: ({
      from,
      to,
      amount,
    }: {
      from: string;
      to: string;
      amount: number;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/content/convert-currency`,
        params: { from, to, amount },
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjVmOTJhNjIwMTMzMzJlMGY2Njc3OTRkYyIsImVtYWlsIjoiY2xpZW50MUBzZWVkLmNvbSIsInZlcnNpb24iOjEsInR5cGUiOiJDTElFTlQiLCJpYXQiOjE3MDgwMTI3MTYsImV4cCI6MTcwODA5OTExNn0.N_jqvB9X82y-ZXEUZAiZEZWYM_ZOMyYm2rAVd271DPQ",
        },
      });
    },
  },
};

export default API;
